<template>
  <div class="Eligibility" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Tester mon admissibilité à l'aide à la garde d'enfants</h1>
        <div class="form_div">
          <div class="form_content">
            <div v-if="isEligibility =='TEST'">
              <h2>
                Pour tester votre éligibilité à l'aide financière pour la garde d'enfants,
                remplissez les champs ci-dessous, puis cliquez sur le bouton "Tester mon éligibilité"
              </h2>

              <div class="mt-4">

                <p>
                  <strong>1. Votre situation</strong>
                </p>
                <b-form-group>
                  <label>Êtes-vous <strong>artistes ou technicien(ne)s intermittents</strong> ?</label>
                  <b-form-radio-group
                    id="isArtisteTechniciens-input"
                    v-model="isArtisteTechniciens"
                    :options="trueFalseOptions"
                    name="isArtisteTechniciens-input"
                  ></b-form-radio-group>
                </b-form-group>

                <b-form-group>
                  <label>Votre demande concerne-t-elle la garde d'un <strong>enfant à charge âgé de moins de 4 ans</strong> au moment de la demande ?</label>
                  <b-form-radio-group
                    id="hasLessThanFourYearsChild-input"
                    v-model="hasLessThanFourYearsChild"
                    :options="trueFalseOptions"
                    name="hasLessThanFourYearsChild-input"
                  ></b-form-radio-group>
                </b-form-group>

                <b-form-group>
                  <label>Percevez-vous <strong>d'autres aides pour la même prestation</strong> (département, région, etc.) ?</label>
                  <b-form-radio-group
                    id="hasOtherAides-input"
                    v-model="hasOtherAides"
                    :options="trueFalseOptions"
                    name="hasOtherAides-input"
                  ></b-form-radio-group>
                </b-form-group>

                <b-form-group>
                  <label>Avez-vous bénéficié d'<strong>au moins une indemnisation Pôle Emploi au titre des annexes 8 et 10 au cours des 24 mois</strong> précédents votre demande d'aide ?</label>
                  <b-form-radio-group
                    id="isIndemnitesPoleEmploi-input"
                    v-model="isIndemnitesPoleEmploi"
                    :options="trueFalseOptions"
                    name="isIndemnitesPoleEmploi-input"
                  ></b-form-radio-group>
                </b-form-group>

                <b-form-group>
                  <label>Votre demande concerne-t-elle des heures de garde pendant lesquelles vous travailliez en <strong>CDD / CDDU dans une entreprise du spectacle</strong> ?</label>
                  <b-form-radio-group
                    id="isAideHeuresGardesCCD-input"
                    v-model="isAideHeuresGardesCCD"
                    :options="trueFalseOptions"
                    name="isAideHeuresGardesCCD-input"
                  ></b-form-radio-group>
                </b-form-group>

                <p>
                  <strong>2. Votre revenu </strong>
                </p>

                <b-form-group id="eligibilite-situation-familiale-group"
                  label="Votre situation familiale"
                  label-for="eligibilite-situation-familiale-input"
                  class="mandatory-input">
                  <b-form-select
                    v-model="rfrSituationfamiliale"
                    id="eligibilite-situation-familiale-input"
                    @input="$v.rfrSituationfamiliale.$touch()"
                    :state="$v.rfrSituationfamiliale.$dirty ? !$v.rfrSituationfamiliale.$error : null"
                    :options='situationFamilialeTypeArrayForm'>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  id="eligibilite-rfrValue-group"
                  :label="enCouple ?
                            'Revenu brut global du couple (ou votre revenu brut global en cas de déclaration séparée) indiqué sur votre avis d\'imposition N-2' :
                            'Votre revenu brut global indiqué sur votre avis d\'imposition N-2'
                          "
                  label-for="eligibilite-rfrValue-input"
                  class="mandatory-input"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model.trim.lazy="rfrValue"
                    id="eligibilite-rfrValue-input"
                    @input="$v.rfrValue.$touch()"
                    :state="$v.rfrValue.$dirty ? !$v.rfrValue.$error : null"
                    placeholder="Revenu brut global en euros">
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-if="enCouple"
                  id="eligibilite-rfrConjointValue-group"
                  label="Revenu brut global de votre conjoint (en cas de déclaration séparée)"
                  label-for="eligibilite-rfrConjointValue-input"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model.trim.lazy="rfrConjointValue"
                    id="eligibilite-rfrConjointValue-input"
                    @input="$v.rfrConjointValue.$touch()"
                    :state="$v.rfrConjointValue.$dirty ? !$v.rfrConjointValue.$error : null"
                    placeholder="Revenu brut global de votre conjoint en euros">
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="eligibilite-rfrNbEnfantsCharge-group"
                  label="Nombre d'enfants fiscalement à charge"
                  label-for="eligibilite-rfrNbEnfantsCharge-input"
                  class="mandatory-input"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model.trim.lazy="rfrNbEnfantsCharge"
                    id="eligibilite-rfrNbEnfantsCharge-input"
                    @input="$v.rfrNbEnfantsCharge.$touch()"
                    :state="$v.rfrNbEnfantsCharge.$dirty ? !$v.rfrNbEnfantsCharge.$error : null"
                    placeholder="Nombre d'enfants">
                  </b-form-input>
                </b-form-group>

                <p>
                  <strong>3. Vos abattements fiscaux</strong>
                </p>
                
                <div class="row">
                  <div
                    v-if="!enCouple"
                     class="col-12 col-md-4 mt-2"
                  >
                    <p> 
                      Vos abattements fiscaux (du demandeur) 
                    </p>
                    <div
                      class="abattement-item"
                      v-for="(abattement, index) in abattements" :key="`abattements-${index}`"
                    >
                      <div>{{abattement}} €</div>
                      <div 
                        @click="removeAbatemment(index)"
                        class="abattement-item--close-window" 
                        v-b-popover.hover.top="'Supprimer'"
                      >
                        <div class="abattement-item--close-window--cross">&#x2716;</div>
                      </div>
                    </div>

                    <b-input-group 
                      v-if="abattements.length < 6"
                      class="mt-3"
                    >
                      <b-form-input
                        type="number"
                        step="1"
                        @keyup.enter="addAbattement"
                        v-model="abattementValue"
                      />
                      <b-input-group-append>
                        <b-button 
                          @click="addAbattement"
                          variant="outline-success"
                        >
                          Ajouter
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div 
                    v-if="enCouple"
                    class="col-12 col-md-4 mt-2"
                  >
                    <p> 
                      Abattements fiscaux du couple <br>
                      <small>(ou les vôtres en cas de déclaration séparée)</small>
                    </p>
                    <div
                      class="abattement-item"
                      v-for="(abattement, index) in abattementsCouple" :key="`abattements-couple-${index}`"
                    >
                      <div>{{abattement}} €</div>
                      <div 
                        @click="removeAbatemmentCouple(index)"
                        class="abattement-item--close-window" 
                        v-b-popover.hover.top="'Supprimer'"
                      >
                        <div class="abattement-item--close-window--cross">&#x2716;</div>
                      </div>
                    </div>
                    <b-input-group 
                      v-if="abattementsCouple.length < 6"
                      class="mt-3"
                    >
                      <b-form-input
                        type="number"
                        step="1"
                        v-model="abattementCoupleValue"
                        @keyup.enter="addAbattementCouple"
                      />
                      <b-input-group-append>
                        <b-button @click="addAbattementCouple" variant="outline-success">Ajouter</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div 
                    v-if="enCouple"
                    class="col-12 col-md-4 mt-2"
                  >
                    <p> 
                      Abattements fiscaux de votre conjoint <br>
                      <small>(en cas de déclaration séparée)</small>
                    </p>
                    <div
                      class="abattement-item"
                      v-for="(abattement, index) in abattementsConjoint" :key="`abattements-conjoint-${index}`"
                    >
                      <div>{{abattement}} €</div>
                      <div 
                        @click="removeAbatemmentConjoint(index)"
                        class="abattement-item--close-window" 
                        v-b-popover.hover.top="'Supprimer'"
                      >
                        <div class="abattement-item--close-window--cross">&#x2716;</div>
                      </div>
                    </div>
                    <b-input-group 
                      v-if="abattementsConjoint.length < 6"
                      class="mt-3"
                    >
                      <b-form-input
                        type="number"
                        step="1"
                        v-model="abattementConjointValue"
                        @keyup.enter="addAbattementConjoint"
                      />
                      <b-input-group-append>
                        <b-button @click="addAbattementConjoint" variant="outline-success">Ajouter</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
                <b-button
                  :disabled="isInvalidEligibilityInputs"
                  :variant="testEligibilityButtonStyle"
                  pill
                  block
                  class="mt-3"
                  size="lg"
                  @click='testEligibility'
                >
                    <b-spinner small label="Test Eligibility Spinner" v-if="eligibilityInProcess"></b-spinner> {{ testEligibilityButtonText }}
                </b-button>
              </div>

              <hr>

              <div class="row text-center">
                <div class="col-12">
                    <contactComponent />
                </div>
              </div>
            </div>

            <!-- Eligibilité Validé -->

            <div v-if="isEligibility =='OK'">
              <h2 class="text-center">
                Vous êtes bien éligible au dispositif de demande d'aide à la garde d'enfants.
              </h2>
              <p>
                Vous pouvez dès maintenant créer votre compte, accéder à votre tableau de bord, renseigner toutes vos informations,
                télécharger directement en ligne vos
                pièces justificatives et faire votre demande.
              </p>

              <b-button
                pill block variant="outline-info" class="mt-2 btn-lg"
                @click='goToAccountCreation'>
                Je crée mon compte et envoie mes pièces justificatives
              </b-button>


              <hr>

              <div class="row text-center">
                <div class="col-12">
                  <contactComponent />
                </div>
              </div>
            </div>


            <!-- Eligibilité Invalidé -->

            <div v-if="isEligibility =='NO'">
              <h2 class="text-center">
                Vous n'êtes pas éligible et ne pouvez déposer de demande d'aide à la garde d'enfants
              </h2>
              <p>
                En effet, cette aide est accessible aux <strong>artistes et technicien(ne)s</strong> :
                <ul>
                  <li>ayant bénéficié d’<strong>au moins une indemnisation Pôle Emploi au titre des annexes 8 et 10 au cours des 24 mois précédents la demande d’aide</strong></li>
                  <li>pour la garde d’<strong>un enfant à charge âgé de moins de 4 ans</strong> au moment de la demande</li>
                  <li>pendant leurs heures de travail en <strong>CDD ou CDDU dans une entreprise du spectacle</strong></li>
                </ul>
                Cette aide est <strong>exclusive et non cumulable</strong> avec d’autres aides à la garde d'enfants pour la même prestation.
              </p>
              <p>
                De plus, le revenu brut global et les montants des abattements ne doivent pas dépasser <strong>un plafond de 60 000€ annuel.</strong>
                Ce plafond est majoré de <strong>3 000€ par enfant fiscalement à charge</strong>.
              </p>

              <b-button
                pill block variant="outline-info" class="mt-4"
                @click="$router.push('/')">
                Retour à l'accueil
              </b-button>

              <hr>

              <div class="row text-center">
                <div class="col-12">
                  <contactComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, decimal, integer} from 'vuelidate/lib/validators'

import contactComponent from '@/components/contactComponent'
import formTitleComponent from '@/components/formTitleComponent'

export default {
  name: 'Eligibility',
  components: {
    formTitleComponent,
    contactComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isEligibility: "TEST", // TEST : Elgibilité en cours de test ; OK : Est éligibile ; NO : n'est pas éligible

      isArtisteTechniciens: false,
      hasLessThanFourYearsChild: false,
      hasOtherAides: false,
      isIndemnitesPoleEmploi : false,
      isAideHeuresGardesCCD: false,

      situationFamilialeTypeArrayForm: [
        { text: 'Célibataire', value: 'CELIB' },
        { text: 'Marié(e)', value: 'MAR' },
        { text: 'Divorcé(e)', value: 'DIV' },
        { text: 'Séparé(e)', value: 'SEP' },
        { text: 'Pacsé(e)', value: 'PACS' },
        { text: 'Vie maritale / Concubinage', value: 'VIEMAR' },
      ],
      rfrSituationfamiliale: null,
      rfrValue: null,
      rfrConjointValue: null,
      rfrConjointAbattements: [],
      abattements: [],
      abattementValue: null,
      abattementsCouple: [],
      abattementCoupleValue: null,
      abattementsConjoint: [],
      abattementConjointValue: null,
      rfrNbEnfantsCharge: 0,


      trueFalseOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value:  false },
      ],

      testEligibilityButtonText: "Tester mon éligibilité à l'aide",
      testEligibilityButtonStyle: 'outline-info',
      eligibilityInProcess: false
    }
  },

  computed: {
    enCouple () {
      if (this.rfrSituationfamiliale === 'MAR' || this.rfrSituationfamiliale === 'PACS' || this.rfrSituationfamiliale === 'VIEMAR') {
        return true
      }
      else {
        return false
      }
    },
    rfrTotal () {
      if (!this.rfrSituationfamiliale) {
        return null
      }
      if (this.enCouple) {
        const totalAbattementCouple = this.abattementsCouple.reduce((total, abattement) => {
          return total + Number(abattement)
        }, 0)
        const totalAbattementConjoint = this.abattementsConjoint.reduce((total, abattement) => {
          return total + Number(abattement)
        }, 0)
        const totalAbattements = totalAbattementCouple + totalAbattementConjoint

        // Alors on est sur un couple
        if (this.rfrConjointValue && Number(this.rfrConjointValue) > 0) {
          return Number(this.rfrValue) + Number(this.rfrConjointValue) + Number(totalAbattements)
        }
        else {
          return Number(this.rfrValue) + Number(totalAbattements)
        }
      }
      else {
        const totalAbattements = this.abattements.reduce((total, abattement) => {
          return total + Number(abattement)
        }, 0)
        // Alors on est sur un célib
        return Number(this.rfrValue) + Number(totalAbattements)
      }
    },
    rfrPlafond () {
      /*  plafond de ressource unique : 60 000 €  + 3 000 € par enfant à charge fiscale */
      if (!this.rfrSituationfamiliale) {
        return null
      }
      return 60000 + 3000 * Number(this.rfrNbEnfantsCharge)
    },
    isInvalidEligibilityInputs () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  validations: {
    rfrSituationfamiliale: {
      required,
    },
    rfrValue: {
      required,
      decimal,
      isSuperior0(value) {
        return value && value > 0
      }
    },
    rfrConjointValue: {
      decimal
    },
    rfrNbEnfantsCharge: {
      required,
      integer
    }
  },
  methods: {
    addAbattement () {
      if (this.abattementValue && this.abattementValue > 0) {
        this.abattements.push(this.abattementValue)
        this.abattementValue = null
      }
    },
    addAbattementConjoint () {
      if (this.abattementConjointValue) {
        this.abattementsConjoint.push(this.abattementConjointValue)
        this.abattementConjointValue = null
      }
    },
    addAbattementCouple () {
      if (this.abattementCoupleValue) {
        this.abattementsCouple.push(this.abattementCoupleValue)
        this.abattementCoupleValue = null
      }
    },
    removeAbatemment(index) {
      this.abattements.splice(index, 1)
    },
    removeAbatemmentConjoint(index) {
      this.abattementsConjoint.splice(index, 1)
    },
    removeAbatemmentCouple(index) {
      this.abattementsCouple.splice(index, 1)
    },
    testEligibility () {
      /* Règle dans le cahier des charges :
       Cette aide est accessible :
       - aux artistes techniciens intermittents
       - Pour un enfant de moins de 4 ans
       - Si il n'y a pas d'autres aides
       - Si il y a eu indemnisation pole emploi durant le 24 derniers mois
       - Si c'est lors d'un travail en CDD /CDDU dans une entreprise du spectacle
       - Si le revenu fiscal de référence inf à
        - 60 000€ annuel
        - avec 3000€ par enfant fiscalement à charge
      */

      this.eligibilityInProcess = true
      this.testEligibilityButtonText = "Test d'éligibilité en cours"
      this.testEligibilityButtonStyle = 'secondary'

      // Premier check pour le première questions
      if (!this.isArtisteTechniciens || !this.hasLessThanFourYearsChild || this.hasOtherAides || !this.isIndemnitesPoleEmploi || !this.isAideHeuresGardesCCD ) {
        this.isEligibility = "NO"
      }
      else {
        // Deuxième check pour les secondes questions sur le revenu fiscal de référence
        if (this.rfrTotal > this.rfrPlafond) {
          this.isEligibility = "NO"
        }
        else {
          this.isEligibility = "OK"
        }
      }

      this.eligibilityInProcess = false
      this.testEligibilityButtonText = "Tester mon éligibilité"
      this.testEligibilityButtonStyle = 'outline-info'
    },
    goToHome () {
      this.$router.push('/')
    },
    goToAccountCreation () {
      this.$router.push('/creation-compte')
    }

  }
}
</script>